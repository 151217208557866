<template>
  <div class="select-hour-box">
    <button :class="[{'selected': selected, 'is-label': isLabel, 'current': currentTime, 'customer-name': customer}, selectedColor, type]" @click="handleSelectTime">
      <template v-if="customer">
        <span>
          {{ customer.name }} {{ customer.family }}
        </span>
      </template>
      <template v-else-if="type === 'closed' && price === '0'">
        <span>
          {{ $t('selectTime.withoutPrice') }}
        </span>
      </template>
      <template v-else >
        <span class="start-time">{{ getTime(time.start_time) || '00' }}</span>
      </template>
    </button>
  </div>
</template>

<script>
export default {
  name: "mobileSelectTime",
  props: {
    time: {},
    court: {},
    date: '',
    disabled: false,
    isLabel: false,
    reload: false,
    price: 0,
    type: '',
    termId: '',
    selectedColor: {
      type: String,
      default: 'success'
    },
    selectReserved: false,
    selectedTimes: {},
    customer: {},
    currentTime: false
  },
  data() {
    return {
      selected: false
    }
  },
  created() {
    const selectedTimes = Object.keys(this.selectedTimes)
    this.selected = selectedTimes.length > 0 && selectedTimes.indexOf(`${this.date}_${this.time.id}_${this.court.id}`) > -1
  },
  methods: {
    handleSelectTime() {
      if (!this.disabled) {
        const selectedTime = {
          time: this.time,
          court: this.court,
          date: this.date,
          price: this.price,
          discount: '0',
          termId: this.termId
        }

        this.$emit(`time:${this.selected ? 'unselected' : 'selected'}`, selectedTime)
        this.selected = !this.selected
      } else if (this.customer) {
        this.$emit('showUser', this.customer)
      }
    },
    getTime (time) {
      let finalTime = []
      const times = time.split(':')
      times.forEach(item => {
        if (parseInt(item) > 0) {
          finalTime.push(parseInt(item).toString())
        }
      })
      return finalTime.join(':')
    }
  },
  watch: {
    reload: {
      handler (val) {
        if (val === true){
          const selectedTimes = Object.keys(this.selectedTimes)
          this.selected = selectedTimes.length > 0 && selectedTimes.indexOf(`${this.date}_${this.time.id}_${this.court.id}`) > -1
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/vuexy/variables";

button {
  position: relative;
  border: none;
  border-right: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  background-color: #ffffff;
  width: 100%;
  height: 45px;
  white-space: nowrap;
  font-size: 12px;
  line-height: 40px;
  padding: 0 5px;
  transition: all ease-in 0.3s;

  &:hover {
    cursor: pointer;
  }

  &.customer-name {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 26px;
  }

  &.disable {
    color: #959595;
    background-color: #e7e7e7;

    &:hover {
      cursor: not-allowed;
    }
  }

  &.selectable {
    color: #6ab0dc;
    background-color: #fff;

    &:hover {
      cursor: pointer;
      color: #fff !important;

      &.success {
        background-color: rgb(52 202 119 / 80%) !important;
      }

      &.danger {
        background-color: rgb(218 30 40 / 80%) !important;
      }
    }
  }

  &.reserved {
    color: #6ab0dc;
    background-color: #eef7fe;
    border-left: 4px solid #6ab0dc;

    &:hover {
      cursor: pointer;
      color: #2e7aab !important;
      background: #cbecff !important;
    }
  }

  &.is-label {
    color: #000000;
  }

  &.closed {
    color: #c73e2e;
    background-color: #ffd1d3 !important;
    border-left: 4px solid #da1e28 !important;

    &:hover {
      cursor: not-allowed;
      /*color: #ffffff !important;
      background: #f7a7a5 !important;*/
    }
  }

  &.current {
    border-top: 3px solid #7ecae9;
    border-bottom: 3px solid #7ecae9;
  }

  &.selected {
    color: #fff !important;
    border-color: #cecece;

    &.success {
      background-color: rgb(52 202 119 / 100%) !important;
    }

    &.danger {
      background-color: #da1e28 !important;
    }
  }

  .start-time {
    font-size: 21px;
  }
}
</style>
