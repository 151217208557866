<template>
  <vx-card class="desktop-weekly-sales-calendar top-zero-radius" :class="[{'main-box': !isMobile}]">

    <div class="date-items">
      <button class="prev-date-btn" @click="changeTableDay(-1)">
        {{ $t('sales.invoices.labels.lastWeek') }}
      </button>
      <div class="date-items">
        <div class="date-content">
          <template v-for="(court, key) in courtsList">
            <button class="date-btn"
                    :class="selectedTableCourt > -1 && key === selectedTableCourt || selectedTableCourt === -1 && key - 1 === weekIndex ? 'active' : ''"
                    @click="setTableCourt(key)">
              {{ court.name }}
              <span v-if="tableDayReserveCount[key] > 0" class="day-reserve-count">
                {{ tableDayReserveCount[key] }}
              </span>
            </button>
          </template>

        </div>
      </div>
      <button class="next-date-btn" @click="changeTableDay(+1)">
        {{ $t('sales.invoices.labels.futureWeek') }}
      </button>
    </div>

    <div class="time-table" :class="isMobile ? 'mobile' : ''">
      <div class="time-table-body">
        <div v-if="timesList.length > 0"
             class="court-row time-label">
          <div class="court-name">{{ $t('sales.invoices.labels.timeWeek') }}</div>
          <select-time v-for="(item, time_index) in timesList[0].times"
                       :time="item.time"
                       :court="item.court"
                       :price="0"
                       :term-id="item.term_id"
                       :is-label="true"
                       :disabled="true"
                       :selected-times="selectedTimes"/>
        </div>
        <div v-if="courtItem.times && courtItem.times.length > 0"
             class="court-row"
             v-for="(courtItem, court_index) in timesList"
             :key="`court_${court_index}`">
          <div class="court-name">{{ courtItem ? courtItem.name : '' }}</div>
          <select-time v-for="(item, time_index) in courtItem.times"
                       :time="item.time"
                       :court="item.court"
                       :price="item.price"
                       :term-id="item.term_id"
                       :customer="item.customer"
                       :current-time="getCurrentHour(item)"
                       :closed="item.status === 0"
                       :passed="item.status === -1"
                       :type="item.type"
                       :date="item.date"
                       :disabled="getTableItemDisabled(item)"
                       :selected-times="selectedTimes"
                       @showUser="handleShowProfile(item)"
                       @time:selected="handleTimeSelected($event)"
                       @time:unselected="handleTimeUnselected($event)"
                       @disableClicked="handleShowDisableReason"/>
        </div>
      </div>
    </div>

    <!-- show user details prompt -->
    <vs-prompt
        class="medium-prompt p-0"
        :buttons-hidden="true"
        title=""
        :active.sync="showUserPromptStatus"
        @close="showUserPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <!--            <div @click="editUserPromptStatus = true">-->
            <!--              <custom-icon icon="EDIT" color="warning"/>-->
            <!--            </div>-->
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('users.user.profile.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showUserPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <user-invoice-profile :user-id="selectedUser.id" :invoice-id="selectedUser.invoiceId"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- show selected times prompt -->
    <vs-prompt
        class="very-big-prompt p-0"
        :buttons-hidden="true"
        title=""
        :active.sync="confirmTimesPromptStatus"
        @close="confirmTimesPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('showPaymentModal')">
              <custom-icon icon="SAVE_PAY" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('sales.invoices.labels.confirm') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="confirmTimesPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <insert-sale-invoice :action-type="newInvoice.actionType" :selected-times="selectedTimes"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <vs-button id="setDateRange" class="useral-action-button" @click="selectInvoiceDateRangePromptStatus = true"/>
    <vs-button id="setTimeSelected" class="useral-action-button" @click="handleShowReserveConfirm"/>
  </vx-card>
</template>

<script>
import moment from "moment-jalaali";
import axios from "axios";
import {getTimes} from "../../../../http/requests/club/times";
import {getCourts} from "../../../../http/requests/club/courts";
import {getReservableTimes} from "../../../../http/requests/booking/sales";
import SelectTime from "../../../../components/reserveTime/selectTime";
import CustomIcon from "../../../../components/customIcon/customIcon";
import {showLoading, hideLoading, getTimeFromServer, checkUserPermissions} from "../../../../assets/js/functions";
import CustomDatePickerInput from "../../../../components/customInput/customDatePickerInput";
import UserInvoiceProfile from "../../sales/invoices/invoice/saleInvoiceModal";
import InsertSaleInvoice from "@/views/admin/sales/invoices/insert/insertSaleInvoice.vue";

export default {
  name: "desktopWeeklySalesCalendar",
  components: {
    InsertSaleInvoice,
    UserInvoiceProfile,
    CustomDatePickerInput, CustomIcon, SelectTime
  },
  metaInfo() {
    return {
      title: this.$t('sales.invoices.calendar.title')
    }
  },
  props: {
    multiSelect: {
      type: Boolean,
      default: () => { return true }
    },
    inModal: {
      type: Boolean,
      default: () => { return false }
    }
  },
  data() {
    return {
      confirmTimesPromptStatus: false,
      showUserPromptStatus: false,
      selectInvoiceDateRangePromptStatus: false,
      showDisableTimeInvoicesPromptStatus: false,
      futureWeeks: 0,
      weekIndex: 0,
      currentTime: moment(getTimeFromServer()).format(this.$localeValidator('moment.date')),
      serverTime: '',
      getCurrentHourTimer: 0,
      selectedTableCourt: -1,
      selectedTableDay: 1,
      selectedDisableTime: {},
      selectedUser: {},
      timesList: [],
      courtsList: [],
      reservedList: [],
      tableDayReserveCount: [],
      selectedTimes: {},
      reserveTypeStatus: 'week',
      startDate: {
        value: '',
        isValid: false
      },
      endDate: {
        value: '',
        isValid: false
      },
      calculatedTimes: [],
      reserveInfo: {},
      newInvoice: {
        id: {
          value: '',
          isValid: true
        },
        actionType: {
          value: 1,
          label: this.$t('sales.invoices.actionTypes.present')
        },
        status: {},
        user: {
          id: 0,
          name: {
            value: '',
            isValid: true
          }
        },
        startDate: {
          value: '',
          isValid: false
        },
        endDate: {
          value: '',
          isValid: false
        },
        date: {
          value: moment(getTimeFromServer()).format(this.$localeValidator('moment.date')),
          isValid: true
        },
        finalPrice: 0,
        totalPrice: 0,
      },
      invoiceProcesses: [
        {
          label: this.$t('sales.invoices.processTypes.preInvoice'),
          value: 1
        },
        {
          label: this.$t('sales.invoices.processTypes.invoice'),
          value: 2
        }
      ],
      actions: [
        {
          toolbar: [
            // {
            //   id: 'setTimeSelected',
            //   icon: 'CHECK',
            //   iconPack: 'useral',
            //   color: 'success',
            //   badge: {
            //     value: 0
            //   }
            // }
          ],
          leftToolbar: [
            {
              id: 'changeTableType',
              icon: 'REFRESH',
              iconPack: 'useral',
              text: ''
            }
          ]
        }
      ]
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.windowWidth <= this.$store.state.mobileScreen
    }
  },
  created() {
    this.serverTime = getTimeFromServer()
    this.currentTime = moment(this.serverTime).format(this.$localeValidator('moment.date'))

    this.weekIndex = (moment(this.serverTime).day() + 1) % 7
    this.selectedTableCourt = 0
    this.startDate = {
      value: this.getTableDate(this.$localeValidator('moment.date'), (this.futureWeeks * 7) - this.weekIndex),
      isValid: true
    }
    this.endDate = {
      value: this.getTableDate(this.$localeValidator('moment.date'), (this.futureWeeks * 7) + 6 - this.weekIndex),
      isValid: true
    }
    this.$store.dispatch('setPageTitle', `${this.startDate.value} ${this.$t('sales.invoices.labels.until')} ${this.endDate.value}`)

    if (!this.inModal) {
      this.$nextTick(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.newInvoice.status = this.invoiceProcesses[0]
      })
    }

    this.getTimes()
    this.getCourts()
  },
  mounted () {
    this.getCurrentHourTimer = setTimeout(() => {
      this.getCurrentHourTimer = 0
    }, 60000 - parseInt(moment(this.currentTime).format(this.$localeValidator('moment.second'))) * 1000)
  },
  methods: {
    getCurrentHour (item) {
      return moment(this.currentTime).format(this.$localeValidator('moment.dateHour')) === `${item.date} ${item.time.start_time.split(':')[0]}`
    },
    getTableDate(formatValue = this.$localeValidator('moment.date'), days = (this.futureWeeks * 7) + this.selectedTableDay - this.weekIndex - 1) {
      return moment(this.serverTime).add(days, 'days').format(formatValue)
    },
    getTableItemDisabled (item) {
      return item.status !== 1 || !item.active || parseInt(item.price) === 0 || this.getCurrentHour(item) || item.customer
    },
    setTableCourt(index = this.selectedTableCourt) {
      this.selectedTableCourt = index
      this.timesList = []
      this.getReservableTimes()
    },
    setTableDate(index = this.selectedTableDay) {
      this.selectedTableDay = index
      const days = (this.futureWeeks * 7) + index - this.weekIndex - 1
      this.timesList = []
      this.getReservableTimes(days)
    },
    getReservableTimes() {
      switch (this.reserveTypeStatus) {
        case 'week':
          this.getReservableTimesBaseWeek()
          break
      }
    },
    changeTableDay(status) {
      if (status > 0) {
        this.futureWeeks += 1
        this.selectedTableDay = 1
      } else {
        this.futureWeeks -= 1
        this.selectedTableDay = 7
      }
      this.timesList = []
      this.startDate.value = this.getTableDate(this.$localeValidator('moment.date'), (this.futureWeeks * 7) - this.weekIndex)
      this.endDate.value = this.getTableDate(this.$localeValidator('moment.date'), (this.futureWeeks * 7) + 6 - this.weekIndex)
      this.setTableDate()
      this.getReserveTableCount()

      this.$store.dispatch('setPageTitle', `${this.startDate.value} ${this.$t('sales.invoices.labels.until')} ${this.endDate.value}`)
    },
    getReservableTimesBaseWeek() {
      showLoading()
      let filters = {type: '5', start_range: this.startDate.value, court_id: this.courtsList[this.selectedTableCourt].id}
      getReservableTimes(1, filters).then((response) => {
        const data = response.data.data
        let totalTimes = {}
        data.forEach(time => {
          if (!totalTimes[time.date]) {
            let court = time.court
            court.times = []
            totalTimes[time.date] = {
              id: time.week_day,
              date: time.date,
              name: moment(time.date, this.$localeValidator('moment.date')).locale(this.$locale.getLanguage()).format(this.$localeValidator('moment.week')),
              times: []
            }
          }

          switch (time.status) {
            case 0:
              time.type = 'closed'
              break

            case 1:
              time.type = time.active ? 'selectable' : 'disable'
              break

            case 2:
              time.type = 'reserved'
              break
          }
          totalTimes[time.date].times.push(JSON.parse(JSON.stringify(time)))
        })
        this.timesList = Object.values(totalTimes)

        this.newInvoice.startDate = this.startDate
        this.newInvoice.endDate = this.endDate

        if (!this._isBeingDestroyed) {
          this.$store.dispatch('setPageTitle', `${this.startDate.value} ${this.$t('sales.invoices.labels.until')} ${this.endDate.value}`)
        }

        hideLoading()
      })
          .catch(error => {
            hideLoading()
            console.log(error)

            // this.selectInvoiceDateRangePromptStatus = true

            const error_mapper = {
              'start_range': this.$t('sales.seasonSchedules.notifications.parseError.startDate'),
              'end_range': this.$t('sales.seasonSchedules.notifications.parseError.endDate'),
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 5000
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('sales.seasonSchedules.notifications.parseError.getTimes'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          })
    },
    getTimes() {
      getTimes().then(response => {
        const times = response.data.data
        times.forEach((time) => {
          this.timesList.push({
            id: time.id,
            startTime: time.start_time.substr(0, 5),
            endTime: time.end_time.substr(0, 5)
          })
        })
      })
    },
    getCourts() {
      getCourts().then((response) => {
        const courts = response.data.data
        courts.forEach((court) => {
          this.courtsList.push({
            id: court.id,
            name: court.name
          })
        })
        this.setTableDate()
      })
    },
    handleShowReserveConfirm () {
      if (Object.values(this.selectedTimes).length > 0) {
        this.confirmTimesPromptStatus = true
      } else {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('sales.invoices.validators.noTime'),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          time: 2400
        })
      }
    },
    handleTimeSelected(selectedTime) {
      delete selectedTime.court.times
      this.selectedTimes[`${selectedTime.date}_${selectedTime.time.id}_${selectedTime.court.id}`] = selectedTime
      let actions = JSON.parse(JSON.stringify(this.$store.state.navbarActions || this.actions[0]))
      if (Object.values(this.selectedTimes).length === 1) {
        actions.toolbar.push({
          id: 'setTimeSelected',
          icon: 'CHECK',
          iconPack: 'useral',
          color: 'success',
          badge: {
            value: 0
          },
          permission: 'invoice.create'
        })
      }
      setTimeout(() => {
        actions.toolbar[0].badge.value = Object.values(this.selectedTimes).length
        this.$store.dispatch('updateNavbarActions', actions)
      }, 100)
      this.getReserveTableCount()

    },
    getReserveTableCount () {
      const selectedTimes = Object.keys(this.selectedTimes)
      let timeCounts = []
      this.courtsList.forEach(court => {
        let count = 0
        let date = court.id
        selectedTimes.forEach((time) => {
          if (parseInt(time.split('_')[2]) === date) {
            count++
          }
        })
        timeCounts.push(count)
      })
      this.tableDayReserveCount = timeCounts
    },
    handleTimeUnselected(unselectedTime) {
      delete this.selectedTimes[`${unselectedTime.date}_${unselectedTime.time.id}_${unselectedTime.court.id}`]
      setTimeout(() => {
        let actions = JSON.parse(JSON.stringify(this.$store.state.navbarActions || this.actions[0]))
        if (Object.values(this.selectedTimes).length === 0) {
          // this.actions[0].toolbar.splice(0, 1)
          actions.toolbar.splice(0, 1)
        } else {
          actions.toolbar[0].badge.value = Object.values(this.selectedTimes).length
        }
        this.$store.dispatch('updateNavbarActions', actions)
      }, 100)
      this.getReserveTableCount()
    },
    handleSetTimesRange () {
      if (!this.startDate.isValid && moment(this.startDate.value, this.$localeValidator('moment.date')).isValid()) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('sales.seasonSchedules.notifications.startDate'),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          time: 2400
        })
        return false
      }
      if (!this.endDate.isValid && moment(this.endDate.value, this.$localeValidator('moment.date')).isValid()) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('sales.seasonSchedules.notifications.endDate'),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          time: 2400
        })
        return false
      }

      if (!moment(this.endDate.value, this.$localeValidator('moment.date')).isAfter(moment(this.startDate.value, this.$localeValidator('moment.date')))) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('sales.seasonSchedules.notifications.seasonDateRange'),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          time: 2400
        })
        return false
      }

      if (moment(this.endDate.value, this.$localeValidator('moment.date')).diff(moment(this.startDate.value, this.$localeValidator('moment.date')), 'days') < 6) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('sales.seasonSchedules.notifications.mustGTEWeek'),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          time: 2400
        })
        return false
      }

      showLoading()
      this.selectedTimes = {}
      this.timesList = []
      this.selectInvoiceDateRangePromptStatus = false
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.getReservableTimes()
      this.getReserveTableCount()
    },

    handleShowDisableReason (time) {
      this.selectedDisableTime = time
      this.showDisableTimeInvoicesPromptStatus = true
    },
    handleShowProfile(item) {
      if (item.customer) {
        this.selectedUser = item.customer
        this.selectedUser.invoiceId = item.invoice_id
        if (checkUserPermissions('user.show') && checkUserPermissions('invoice.show')) {
          this.showUserPromptStatus = true
        }
        // let routeData = this.$router.resolve({name: 'saleInvoice', params: {id: item.invoice_id}})
        // window.open(routeData.href, '_blank')
      }
    },
    handleClick(id) {
      document.getElementById(id).click()
    },
    handleCloseSelectDateRangeModal () {
      if (this.newInvoice.startDate.value === '' && this.newInvoice.endDate.value === '') {
        this.$router.replace({name: 'customerSeasonSchedulesList', params: {userId: this.$route.query.user}})
      } else {
        this.startDate = this.newInvoice.startDate
        this.endDate = this.newInvoice.endDate
        this.selectInvoiceDateRangePromptStatus = false
      }
    }
  },

  watch: {
    getCurrentHourTimer: {
      handler (val) {
        if (val === 0) {
          this.$forceUpdate()
          this.getCurrentHourTimer = setTimeout(() => {
            this.getCurrentHourTimer = 0
          }, 60000)
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../../../assets/scss/vuexy/variables";

.desktop-weekly-sales-calendar {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 100%;

  .vx-card__body {
    padding: 0 !important;
  }

  .date-items {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border: 1px solid #f0f0f0;

    .prev-date-btn,
    .next-date-btn {
      padding: 5px 20px;
      margin: 10px;
      background-color: #f8f8f8;
      border: 1px solid #cecece;
      border-radius: 0.5rem;

      &:hover:not([disabled]) {
        cursor: pointer;
        background-color: #f0f0f0;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    .date-items {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      overflow: auto;
      .date-content {
        display: flex;
        text-align: center;
        overflow-x: auto;
        padding: 5px;
        padding-top: 10px;

        &::-webkit-scrollbar {
          display: block;
          background: rgba(255, 255, 255, 0.50);
          height: 10px;
          width: 10px;
          border-radius: 0.5rem;
        }

        &::-webkit-scrollbar-thumb {
          display: block;
          background: rgba(206, 206, 206, 1);
          height: 6px;
          border-radius: .5rem;
        }

        .date-btn {
          position: relative;
          padding: 5px 20px;
          margin: 0 5px;
          background-color: #f8f8f8;
          border: 1px solid #cecece;
          border-radius: 0.5rem;
          white-space: nowrap;

          &:hover:not([disabled]) {
            cursor: pointer;
            background-color: #f0f0f0;
          }

          &.active {
            background-color: $sidebar-semi-bg;
            color: #ffffff;

            &:hover {
              background-color: #1b2027;
            }
          }

          &:disabled {
            cursor: not-allowed;
          }

          .day-reserve-count {
            position: absolute;
            top: -10px;
            right: calc(50% - 8px);
            height: 16px;
            width: 16px;
            background-color: $success;
            color: #ffffff;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .time-table {
    position: relative;
    flex: 1;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*align-content: start;*/
    /*flex-wrap: wrap;*/
    padding: 10px;
    /*padding-left: 170px;*/
    align-self: flex-start;
    justify-self: flex-start;
    direction: ltr;



    &::-webkit-scrollbar {
      display: block;
      background: rgba(255, 255, 255, 0.50);
      height: 10px;
      width: 10px;
      border-radius: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      display: block;
      background: rgba(206, 206, 206, 1);
      height: 6px;
      border-radius: .5rem;
    }

    &.mobile {
      height: 100%;
    }

    .time-table-body {
      display: flex;
      -webkit-box-orient: revert;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row;
      flex-direction: row;
      position: relative;
      align-self: flex-start;
      justify-self: flex-start;
      margin: auto;
    }

    .court-row {
      position: relative;
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      width: calc(100vw / 7);
      min-width: 80px;
      max-width: 150px;

      @media screen and (max-width: 768px) {
        width: 100px;
      }

      .court-name {
        width: 100%;
        line-height: 60px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $sidebar-semi-bg;
        /*border-radius: 0.5rem 0.5rem 0 0;*/
        position: relative;
        align-self: flex-start;
        color: #ffffff;
        font-weight: 500;
        border-right: 1px solid #cdcdcd;
        border-bottom: 1px solid #cdcdcd;
        z-index: 90;


        @supports (position: sticky) {
          position: -webkit-sticky;
          position: sticky;
          top: -11px;
        }
      }

      &:first-child {
        .court-name {
          border-radius: 0.5rem 0 0 0;
        }
      }

      &:last-child {
        .court-name {
          border-radius: 0 0.5rem 0 0;
          border-right: none;
        }

        .select-hour-box:last-child {
          button {
            border-radius: 0 0 0.5rem 0;
          }
        }
      }
    }

    .time-label {
      position: relative;
      /*position: -webkit-sticky;*/
      /*background-color: #f8f8f8;*/
      /*align-self: flex-start;*/
      /*justify-self: flex-start;*/
      z-index: 100;
      width: 90px;

      @supports (position: sticky) {
        position: -webkit-sticky;
        position: sticky;
        left: -10px;
      }

      @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        position: -webkit-sticky;
        position: sticky;
        left: 0;
      }

      .court-name {
        z-index: 101;
      }

      .select-hour-box {
        position: relative;
        height: 60px;
        left: 0;
        right: 0;

        .is-label {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $sidebar-semi-bg;
          color: #ffffff;
          z-index: 100;
        }
      }

      .select-hour-box:last-child {
        .is-label {
          border-radius: 0 0 0 0.5rem;
        }
      }
    }
  }
}

.action-buttons {
  display: flex;

  button {
    flex: 1;
    line-height: 40px;
    border-radius: .5rem;
    border: none;
    color: #ffffff;
    transition: all .3s ease;
    cursor: pointer;

    &.payment {
      margin-right: 10px;
      background: #33b000cf;

      &:hover {
        background: #33b000;
      }
    }

    &.cancel {
      background: #b00000af;

      &:hover {
        background: #b00000;
      }
    }
  }
}
</style>
